import Layout from "../components/global/Layout";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import PackageJSON from '../../package.json';

const Container = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow:column;
  min-height:80vh;
`;

const AppVersionPage = ({ location }) => {
  return (
    <Layout location={location} title={"App Version"}>
      <Container>
        <h1>App version: {PackageJSON.version}</h1>
        <Link to="/">Go back home</Link>
      </Container>
    </Layout>
  )
}

export default AppVersionPage;